import { Button, Form, Input, Select, Typography } from 'antd';
import { ViewPermission, useAlert, phoneNoValidator, useB2CClient, OverlayLoader } from '@job-commander/shared';
import React, { useState, FC, useRef, useEffect } from 'react';
import { useLoader } from '@surinderlohat/react-hooks';
import '../AuthorizationPage.less';
import './VerifyPhone.less';
import { useDataService } from 'src/api';
import { ICandidateSendOTP, ICandidateVerifyCode } from 'src/interfaces';
import { useHistory } from 'react-router-dom';
import { useAppStores } from 'src/store';

const { Title } = Typography;

const VerifyPhone: FC = props => {
  const alert = useAlert();
  const loader = useLoader(false);
  const sendCodeRef = useRef<any>();
  const VerifyCodeRef = useRef<any>();
  const history = useHistory();
  const [ options, setOptions ] = useState([]);
  const [ isCodeView, setIsCodeView ] = useState(false);
  const dataService = useDataService();
  const b2cClient = useB2CClient();
  const { userStore } = useAppStores();

  const verifyUserLoader = useLoader(true);

  useEffect(() => {
    // will redirect to landing page if user not authorized
    if (!b2cClient.isAuthorized) {
      history.replace('/');
      return;
    }

    // If Phone number already verified then redirect to landing page
    if (userStore.user.isPhoneNumberVerified) {
      const url = userStore.user.isSignupCompleted ? '/a/matches' : '/complete-signup';
      history.replace(url);
      return;
    }
    verifyUserLoader.hideLoader();
    loadCountryOptions();
  }, []);

  const loadCountryOptions = async () => {
    loader.showLoader();
    const response = await dataService.loadCountryOptions();
    setOptions(response);
    loader.hideLoader();
  };

  const sendCode = async request => {
    loader.showLoader();
    const response = await dataService.sendCode(request);
    loader.hideLoader();
    if (response.hasError) {
      return;
    }
    alert.success(response.data);
    setIsCodeView(true);
  };

  const onSendCode = async () => {
    try {
      await sendCodeRef.current?.validateFields();
      const values = sendCodeRef.current?.getFieldsValue();
      // Loading user employee profile
      const request: ICandidateSendOTP = {
        phoneNumber: values.phoneNo,
        countryCode: values.countryCode.value,
      };
      sendCode(request);
    } catch (error) {
      if (error.errorFields) {
        error.errorFields.forEach((field) => {
          if (field.name.includes('phoneNo')) {
            field.errors = field.errors.filter((err) => !err.includes('phoneNo'));
          }
        });
  
        // Display updated error messages
        sendCodeRef.current?.setFields(error.errorFields);
      }
    }
  };

  const onVerifyCode = async () => {
    try {
      await sendCodeRef.current?.validateFields();
      await VerifyCodeRef.current?.validateFields();
      const otp = VerifyCodeRef.current?.getFieldsValue();
      const phoneValues = sendCodeRef.current?.getFieldsValue();
      const request: ICandidateVerifyCode = {
        phoneNumber: phoneValues.phoneNo,
        countryCode: phoneValues.countryCode.value,
        otp: otp.code,
      };
      loader.showLoader();
      const response = await dataService.verifyCode(request);
      loader.hideLoader();
      if (response.hasError) {
        return;
      }
      userStore.setIsPhoneNumberVerified();
      history.replace('/complete-signup');
    } catch (e) {
      console.log(e);
    }
  };
  
  return (
    <OverlayLoader showChildren={!verifyUserLoader.isLoading}>
      <div className="auth-container">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Form id="verify-phone" name="loginForm" size="large" ref={sendCodeRef} 
            initialValues={{ countryCode: { key: '+1', label: 'United States(+1)', value: '+1' } }}>
            <Title level={5}>Job Commander</Title>
            <Form.Item name="countryCode" rules={[{ required: true }]} hasFeedback hidden>
              {/* <Select
                options={options}
                showSearch
                placeholder="Select Country Code"
                labelInValue
                showArrow={true}
                style={{ display: 'inline-block', width: '100%' }}
                filterOption={(input, option) => option?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              /> */}
            </Form.Item>

            <Form.Item name="phoneNo" rules={[
              { required: true, message: 'Phone number is required' }, phoneNoValidator() ]} hasFeedback>
              <Input placeholder="Phone Number" />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button type="primary" onClick={onSendCode} disabled={loader.isLoading}>
                Send Verification Code
              </Button>
            </div>
          </Form>
          <ViewPermission isVisible={isCodeView}>
            <Form id="verify-phone" name="loginForm" size="large" ref={VerifyCodeRef}>
              <Form.Item name="code" rules={[{ required: true }]} hasFeedback>
                <Input placeholder="Phone Verification Code " style={{ marginTop: '10px' }} />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  type="primary"
                  style={{ marginRight: '2px' }}
                  onClick={onVerifyCode}
                  disabled={loader.isLoading}
                >
                  Verify Code
                </Button>
                <Button type="primary" onClick={onSendCode} disabled={loader.isLoading}>
                  Send New Code
                </Button>
              </div>
            </Form>
          </ViewPermission>
        </div>
      </div>
    </OverlayLoader>
  );
};

export default VerifyPhone;
