import {
  APIResponse,
  HttpClient,
  IPageResponse,
  QuestionModel,
  SelectOptionModel,
  useAccessToken,
  useAlert,
  Utilities,
} from '@job-commander/shared';
import { USER_TYPE } from 'src/enums';
import { ISignupRequest } from 'src/interfaces/ISignupRequest.interface';
import { baseAPI } from '../config';
import { apiUrls } from './apiUrls';
import { generalMessage } from 'src/components/Messages';
import { CandidateModel, OptionsModel, ProductModel, UserModel } from 'src/models';
import { EmployerModel } from 'src/models/Employer.model';

import {
  ICandidateRequest,
  IProductsResponse,
  IEmployer,
  IJob,
  IJobDeletionRequest,
  IAdjustCompatibility,
  IToggleFavouriteRequest,
  IApplyInviteRequest,
  IAnswers,
  ISuperMatchAnswers,
  ISuperMatchAnswersResponse,
  ISuperMatchCreditsResponse,
  ICandidateSendOTP,
  ICandidateVerifyCode,
  IChats,
  IChatByIdResponse,
  IChatReply,
  ISchedule,
  IAdditionalInfo,
  IEditNote,
  IReason,
} from 'src/interfaces';
import { JobModel } from 'src/models/Job.model';
import { IPageRequest } from '@surinderlohat/react-hooks';
import { JobDeletionOptions } from 'src/models/JobDeletionOption.model';
import { JobWithNamesModel } from 'src/models/JobWithNames.model';
import { MatchesResponse } from 'src/models/MatchesResponse.model';
import { MatchesResultModel } from 'src/models/MatchesResult.model';
import { CountryModel } from 'src/models/CountryModel.model';
import { HiredCandidateNamesModel } from 'src/models/HiredCandidateNames.model';
import { ChatByIdResponseModel } from 'src/models/ChatByIdResponse.model';
import { ChatModel } from 'src/models/Chat.model';
import { IContactUs } from 'src/interfaces/API-ContactUs.interface';
import { CountdownModel } from 'src/models/CountdownModel';
import { CheckEmployerAccountDelete } from 'src/interfaces/API-CheckEmployerAccountDelete.interface';

export interface IDataService {
  login: (request: any) => Promise<any>;
  signup: (type: USER_TYPE, request: ISignupRequest) => Promise<any>;
  loadEducationOptions: () => Promise<OptionsModel>;
  loadInterviewOptions: () => Promise<APIResponse<SelectOptionModel[]>>;
  loadTimezoneOptions: () => Promise<APIResponse<SelectOptionModel[]>>;
  loadJobOptions: () => Promise<OptionsModel>;
  loadCountryOptions: () => Promise<CountryModel[]>;
  searchCollages: (search: string) => Promise<SelectOptionModel[]>;
  searchIndustries: (search: string) => Promise<SelectOptionModel[]>;
  getQuestions: () => Promise<any>;
  countdownDates: () => Promise<CountdownModel>;
  getPaidQuestions: (emplJobId?: number, isCandidate?: boolean) => Promise<any>;
  getSuperMatchQuestions: (data: IApplyInviteRequest) => Promise<any>;
  getProfile: (isCandidate: boolean) => Promise<APIResponse<CandidateModel | EmployerModel>>;
  getProfileBy: (isCandidate: boolean, userId: string) => Promise<APIResponse<CandidateModel | EmployerModel>>;
  candidateUpdateProfile: (request: ICandidateRequest) => Promise<APIResponse<CandidateModel>>;
  candidateCompleteSignup: (data: ICandidateRequest) => Promise<APIResponse<CandidateModel>>;
  getProducts: () => Promise<APIResponse<IProductsResponse>>;
  employerUpdateProfile: (request: IEmployer) => Promise<APIResponse<EmployerModel>>;
  employerCompleteSignup: (request: IEmployer) => Promise<APIResponse<EmployerModel>>;
  getEmployerJobs: (pageRequest?: Partial<IPageRequest>) => Promise<APIResponse<IPageResponse<JobModel[]>>>;
  getExpiredJobs: () => Promise<APIResponse<JobModel[]>>;
  getCandidateNames: (employerJobId: number) => Promise<APIResponse<HiredCandidateNamesModel[]>>;
  updateEmployerJobs: (request: IJob) => Promise<APIResponse<JobModel>>;
  searchOrCandidateJobs: (
    filterCollection: { id: number; searchValue: string },
    request: Partial<IPageRequest>,
    isCandidate: boolean
  ) => Promise<APIResponse<IPageResponse<JobModel>>>;
  removeJobQuestions: () => Promise<any>;
  deleteJob: (request: IJobDeletionRequest) => Promise<APIResponse<any>>;
  deleteJobFromEmail: (request: IJobDeletionRequest) => Promise<APIResponse<any>>;
  getEmployerJobsByCandidate: (
    emplId: number,
    pageRequest?: Partial<IPageRequest>
  ) => Promise<APIResponse<IPageResponse<JobModel>>>;
  getMatchesData: (request: Partial<IPageRequest>, emplJobId?: number) => Promise<APIResponse<IPageResponse<JobModel>>>;
  getJobsWithNames: () => Promise<APIResponse<JobWithNamesModel[]>>;
  checkIsAccountDeletable: () => Promise<APIResponse<CheckEmployerAccountDelete>>;
  pendingDueJobs: () => Promise<APIResponse<CheckEmployerAccountDelete>>;
  adjustMinCompatScore: (request: IAdjustCompatibility) => Promise<APIResponse<MatchesResponse>>;
  toggleFavourite: (request: IToggleFavouriteRequest) => Promise<any>;
  getFavourites: (request: Partial<IPageRequest>) => Promise<APIResponse<IPageResponse<MatchesResponse>>>;
  applyOrInvite: (request: IApplyInviteRequest) => Promise<any>;
  getQuestionCount: (emplJobId?: number) => Promise<any>;
  saveQuestionsAnswers: (data: IAnswers, isCandidate: boolean) => Promise<APIResponse<any>>;
  saveSuperMatchAnswers: (
    data: ISuperMatchAnswers,
    isCandidate: boolean
  ) => Promise<APIResponse<ISuperMatchAnswersResponse>>;
  getSuperMatchCredits: () => Promise<APIResponse<ISuperMatchCreditsResponse>>;
  deActivateAccount: (isCandidate) => Promise<APIResponse<boolean>>;
  deleteAccount: (isCandidate) => Promise<APIResponse<boolean>>;
  sendCode: (data: ICandidateSendOTP) => Promise<APIResponse<any>>;
  verifyCode: (data: ICandidateVerifyCode) => Promise<APIResponse<any>>;
  getChatSessions: () => Promise<APIResponse<IChats[]>>;
  getMessagesById: (id: number) => Promise<APIResponse<IChatByIdResponse[]>>;
  sendChatReply: (data: IChatReply) => Promise<APIResponse<any>>;
  sendScheduleReply: (data: ISchedule) => Promise<APIResponse<any>>;
  sendAdditionalInfo: (data: IAdditionalInfo) => Promise<APIResponse<any>>;
  updateAdditionalNotes: (data: IEditNote) => Promise<APIResponse<any>>;
  sendReason: (data: IReason) => Promise<APIResponse<any>>;
  getUnseenMessageCount: () => Promise<APIResponse<any>>;
  trackLastSeen: (chatId: number) => Promise<APIResponse<any>>;
  getSessionData: (forceRefresh?: boolean) => Promise<APIResponse<UserModel>>;
  setTutorialDone: () => Promise<APIResponse<any>>;
  contactUs: (data: IContactUs) => Promise<APIResponse<any>>;
}

export const useDataService = (): IDataService => {
  const alert = useAlert();
  const accessToken = useAccessToken();

  // use login request
  const signup = async (type: USER_TYPE, request: ISignupRequest) => {
    request.phoneNo = (request.phoneNo || '').replace(/\D/gi, '');

    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.signupCandidate, {
      ...request,
      password: request.pwd,
      phoneNumber: request.phoneNo,
    });
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return response.data;
  };

  // use login request
  const login = async request => {
    request.phoneNo = (request.phoneNo || '').replace(/\D/gi, '');
    request.phoneCode = request.phoneCode || '';

    const http = new HttpClient({ baseURL: baseAPI.jobCommander });
    const response = await http.post(apiUrls.login, request);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return response.data;
  };

  // use login request
  const loadEducationOptions = async (): Promise<OptionsModel> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.educationOptions);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return OptionsModel.deserialize(response.data);
  };

  const loadCountryOptions = async (): Promise<CountryModel[]> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.getCountries);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return CountryModel.deserializeList(response.data);
  };

  const loadInterviewOptions = async (): Promise<APIResponse<SelectOptionModel[]>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.getInterviewOptions);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return {
      ...response,
      data: SelectOptionModel.deserializeList(response.data),
    };
  };

  const loadTimezoneOptions = async (): Promise<APIResponse<SelectOptionModel[]>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.getTimezoneOptions);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return {
      ...response,
      data: SelectOptionModel.deserializeList(response.data),
    };
  };

  // use login request
  const loadJobOptions = async (): Promise<OptionsModel> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.jobOptions);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return OptionsModel.deserialize(response.data);
  };

  // use login request
  const searchCollages = async (search: string): Promise<SelectOptionModel[]> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(`${apiUrls.colleges}?search=${search}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return SelectOptionModel.deserializeList(response.data);
  };

  // Search industry
  const searchIndustries = async (search: string): Promise<SelectOptionModel[]> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(`${apiUrls.industries}?search=${search}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return SelectOptionModel.deserializeList(response.data);
  };

  const getQuestions = async () => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.getQuestions);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return QuestionModel.deserializeList(response.data);
  };

  const countdownDates = async () => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.countdownDates);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return CountdownModel.deserialize(response.data);
  };

  const getPaidQuestions = async (emplJobId?: number, isCandidate?: boolean) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const url = isCandidate ? apiUrls.paidQuestions : `${apiUrls.paidQuestions}/${emplJobId}`;
    const response = await http.get(url);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return QuestionModel.deserializeList(response.data);
  };

  const getSuperMatchQuestions = async (data: IApplyInviteRequest) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.superMatchQuestions, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return QuestionModel.deserializeList(response.data);
  };

  const getProducts = async (): Promise<APIResponse<IProductsResponse>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.getProducts);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return {
      ...response,
      data: {
        superMatch: ProductModel.deserializeList(response.data?.superMatch),
        questionPackage: ProductModel.deserializeList(response.data?.questionPackage),
        subscriptionPackage: ProductModel.deserializeList(response.data?.subscriptionPackage),
        extensionSubscriptionPackage: ProductModel.deserializeList(response.data?.extensionSubscriptionPackage),
        JobExpirationDate : response.data?.JobExpirationDate ,
      },
    };
  };

  const candidateUpdateProfile = async (data: ICandidateRequest): Promise<APIResponse<CandidateModel>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.completeProfile, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: CandidateModel.deserialize(response.data),
    };
  };

  const candidateCompleteSignup = async (data: ICandidateRequest): Promise<APIResponse<CandidateModel>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.completeProfile, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: CandidateModel.deserialize(response.data),
    };
  };

  const employerUpdateProfile = async (data: IEmployer): Promise<APIResponse<EmployerModel>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.employerProfile, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: EmployerModel.deserialize(response.data),
    };
  };

  const employerCompleteSignup = async (data: IEmployer): Promise<APIResponse<EmployerModel>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.employerProfile, data as any);
    if (response.hasError) {
      alert.errorAlert({
        message: response.title,
        description: generalMessage.completeSignupError,
      });
      return response;
    }
    return {
      ...response,
      data: EmployerModel.deserialize(response.data),
    };
  };

  const getProfile = async (isCandidate: boolean): Promise<APIResponse<CandidateModel | EmployerModel>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(isCandidate ? apiUrls.completeProfile : apiUrls.employerProfile);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: isCandidate ? CandidateModel.deserialize(response.data) : EmployerModel.deserialize(response.data),
    };
  };

  const getProfileBy = async (
    isCandidate: boolean,
    userId: string
  ): Promise<APIResponse<CandidateModel | EmployerModel>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const url = isCandidate ? `${apiUrls.employerProfile}/${userId}` : `${apiUrls.completeProfile}/${userId}`;

    const response = await http.get(url);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: isCandidate ? EmployerModel.deserialize(response.data) : CandidateModel.deserialize(response.data),
    };
  };

  const getEmployerJobs = async (
    pageRequest?: Partial<IPageRequest>
  ): Promise<APIResponse<IPageResponse<JobModel[]>>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 10,
      ...pageRequest,
    });
    const response = await http.get(`${apiUrls.employerJobs}?${params}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: {
        ...response.data,
        results: JobModel.deserializeList(response?.data?.results),
      },
    };
  };

  const getExpiredJobs = async (): Promise<APIResponse<JobModel[]>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);

    const response = await http.get(apiUrls.expiredJobs);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: JobModel.deserializeList(response?.data),
    };
  };

  const getCandidateNames = async (employerJobId: number): Promise<APIResponse<HiredCandidateNamesModel[]>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const url = `${apiUrls.candidateNames}/${employerJobId}`;
    const response = await http.get(url);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: HiredCandidateNamesModel.deserializeList(response.data),
    };
  };

  const updateEmployerJobs = async (data: IJob): Promise<APIResponse<JobModel>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.employerJobs, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: JobModel.deserialize(response.data),
    };
  };

  // Search Candidates Or Jobs By Search Query
  const searchOrCandidateJobs = async (
    searchParams: { Id?: number; searchValue: string },
    data: IPageRequest,
    isCandidate: boolean
  ): Promise<APIResponse<IPageResponse<JobModel>>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const url = isCandidate ? apiUrls.candidateSearchJobs : apiUrls.employerSearchCandidates;
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 10,
      ...searchParams,
      ...data,
    });
    const response = await http.get(`${url}?${params}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return { ...response, data: { ...response.data, results: JobModel.deserializeList(response.data.results) } };
  };

  const removeJobQuestions = async () => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.removeJobQuestions);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return JobDeletionOptions.deserializeList(response.data);
  };

  const deleteJob = async (data: IJobDeletionRequest): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.deleteJob, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const deleteJobFromEmail = async (data: IJobDeletionRequest): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.deleteJobFromEmail, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const getFavourites = async (request: IPageRequest): Promise<APIResponse<IPageResponse<MatchesResponse>>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 10,
      ...request,
    });
    const response = await http.get(`${apiUrls.favourites}?${params}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: {
        ...response.data,
        results: MatchesResultModel.deserializeList(response.data.results),
      },
    };
  };

  const getMatchesData = async (
    request: IPageRequest,
    emplJobId?: number
  ): Promise<APIResponse<IPageResponse<JobModel>>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 10,
      ...request,
    });
    const responseResult = emplJobId
      ? await http.get(`${apiUrls.employerMatches}/${emplJobId}?${params}`)
      : await http.get(`${apiUrls.candidateMatches}?${params}`);

    if (responseResult.hasError) {
      alert.error(responseResult.title, responseResult.statusText);
      return responseResult;
    }
    return {
      ...responseResult,
      data: {
        ...responseResult.data,
        results: MatchesResultModel.deserializeList(responseResult.data.results),
      },
    };
  };

  const getEmployerJobsByCandidate = async (
    emplId: number,
    request: IPageRequest
  ): Promise<APIResponse<IPageResponse<JobModel>>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 10,
      ...request,
    });
    const response = await http.get(`${apiUrls.candidateViewEmployerJobs}/${emplId}?${params}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: {
        ...response.data,
        results: MatchesResultModel.deserializeList(response?.data?.results),
      },
    };
  };

  const getJobsWithNames = async (): Promise<APIResponse<JobWithNamesModel[]>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.jobNames);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: JobWithNamesModel.deserializeList(response.data),
    };
  };

  const adjustMinCompatScore = async (request): Promise<APIResponse<MatchesResponse>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.adjustCompatibility, request);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return {
      ...response,
      data: {
        ...response.data,
        results: MatchesResultModel.deserializeList(response.data.results),
      },
    };
  };

  const toggleFavourite = async (request: IToggleFavouriteRequest) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.toggleFavourite, request as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return response.data;
  };

  const applyOrInvite = async (request: IApplyInviteRequest) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.applyOrInvite, request as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return response.data;
  };

  const getQuestionCount = async (emplJobId?: number) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const url = emplJobId ? `${apiUrls.getQuestionCount}/${emplJobId}` : apiUrls.getQuestionCount;
    const response = await http.get(url);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response.data;
  };

  const saveQuestionsAnswers = async (data: IAnswers, isCandidate: boolean) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(
      isCandidate ? apiUrls.candidateSaveAnswers : apiUrls.employerSaveAnswers,
      data as any
    );
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return response.data;
  };

  const getSuperMatchCredits = async (): Promise<APIResponse<ISuperMatchCreditsResponse>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.superMatchCredits);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const saveSuperMatchAnswers = async (data: ISuperMatchAnswers, isCandidate: boolean) => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(
      isCandidate ? apiUrls.saveSuperMatchAnswersCandidate : apiUrls.saveSuperMatchAnswersEmployer,
      data as any
    );
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return;
    }
    return response;
  };
  const deActivateAccount = async (isCandidate): Promise<APIResponse<boolean>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.delete(isCandidate ? apiUrls.candidateDeActivate : apiUrls.employerDeActivate);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const deleteAccount = async (isCandidate): Promise<APIResponse<boolean>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.delete(isCandidate ? apiUrls.candidateDelete : apiUrls.employerDelete);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const checkIsAccountDeletable = async (): Promise<APIResponse<CheckEmployerAccountDelete>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.checkEmployerAccountDelete);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const pendingDueJobs = async (): Promise<APIResponse<CheckEmployerAccountDelete>> => {
    if (!accessToken) {
      return;
    }  
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.pendingDueJobs);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };


  const sendCode = async (data: ICandidateSendOTP): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.sendCode, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const verifyCode = async (data: ICandidateVerifyCode): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.verifyCode, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const getChatSessions = async (): Promise<APIResponse<IChats[]>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.getChats);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return { ...response, data: ChatModel.deserializeList(response.data) };
  };

  const getMessagesById = async (chatId: number): Promise<APIResponse<IChatByIdResponse[]>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(`${apiUrls.getChats}/${chatId}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return { ...response, data: ChatByIdResponseModel.deserializeList(response.data) };
  };

  const sendChatReply = async (data: IChatReply): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.sendChatReply, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const sendScheduleReply = async (data: ISchedule): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.sendScheduleReply, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const sendAdditionalInfo = async (data: IAdditionalInfo): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.sendAdditionalInfo, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const updateAdditionalNotes = async (data: IEditNote): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.put(apiUrls.editNote, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const sendReason = async (data: IReason): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.post(apiUrls.sendReason, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const getUnseenMessageCount = async (): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.unSeenMessage);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const trackLastSeen = async (chatId: number): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(`${apiUrls.trackLastSeen}/${chatId}`);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const setTutorialDone = async (): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.setTutorialDone);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  const getSessionData = async (forceRefresh?: boolean): Promise<APIResponse<UserModel>> => {
    // Pick Data from session catch
    try {
      if (!forceRefresh) {
        const userData = sessionStorage.getItem('user-session');
        if (userData) {
          return Promise.resolve({
            data: UserModel.deserializeUser(JSON.parse(userData)),
            hasError: false,
            statusCode: '200',
            statusText: '',
          });
        }
      }
    } catch (error) {
      console.log('continue get profile');
    }
    // if session not available then fetch from profile
    const http = new HttpClient({ baseURL: baseAPI.jobCommander }).withToken(accessToken);
    const response = await http.get(apiUrls.userClaims);
    if (response.hasError) {
      alert.error(generalMessage.apiError, response.statusText);
      return response;
    }
    return {
      ...response,
      data: UserModel.deserializeUser(response.data),
    };
  };

  const contactUs = async (data: IContactUs): Promise<APIResponse<any>> => {
    const http = new HttpClient({ baseURL: baseAPI.jobCommander });
    const response = await http.post(apiUrls.contactUs, data as any);
    if (response.hasError) {
      alert.error(response.title, response.statusText);
      return response;
    }
    return response;
  };

  return {
    signup,
    login,
    loadEducationOptions,
    loadCountryOptions,
    searchCollages,
    loadJobOptions,
    countdownDates,
    getQuestions,
    candidateUpdateProfile,
    candidateCompleteSignup,
    employerUpdateProfile,
    getProfile,
    getProducts,
    getEmployerJobs,
    employerCompleteSignup,
    updateEmployerJobs,
    searchOrCandidateJobs,
    searchIndustries,
    removeJobQuestions,
    deleteJob,
    deleteJobFromEmail,
    getJobsWithNames,
    adjustMinCompatScore,
    toggleFavourite,
    getFavourites,
    applyOrInvite,
    getProfileBy,
    getQuestionCount,
    getMatchesData,
    getEmployerJobsByCandidate,
    getPaidQuestions,
    getSuperMatchQuestions,
    saveSuperMatchAnswers,
    saveQuestionsAnswers,
    getSuperMatchCredits,
    deActivateAccount,
    deleteAccount,
    checkIsAccountDeletable,
    sendCode,
    verifyCode,
    getCandidateNames,
    getExpiredJobs,
    getChatSessions,
    getMessagesById,
    sendChatReply,
    sendScheduleReply,
    sendAdditionalInfo,
    loadInterviewOptions,
    updateAdditionalNotes,
    sendReason,
    loadTimezoneOptions,
    getUnseenMessageCount,
    trackLastSeen,
    getSessionData,
    setTutorialDone,
    contactUs,
    pendingDueJobs,
  };
};
